import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
import MobileDetect from 'mobile-detect';
import lightbox from 'lightbox2';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'; 

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
    if ($(window).outerWidth(true) > 1199) {
      hoverInit();
    }
  	this.lazyLoad();
    this.menuInit();
    this.designGall();

    if ($('#photosphere').length) {
      viewerInit();
    }
  },
  initScroll: function(){
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header').toggleClass('opened');
      $('.header__nav').fadeToggle(200);
    })

    $('.menu-item-has-children > a').addClass('no-smoothState');
    
    $('.menu-item-has-children').on('click', function(event){
      event.preventDefault();
      $(this).toggleClass('active');
      var sub = $(this).find('.sub-menu');
      if ($(window).outerWidth(true) > 991) {
        sub.toggleClass('active');
      } else {
        sub.slideToggle(200);
      }
    })
  },
  designGall: function(){ 
    if ($('.gal__wrap').length) {
      $('.gal__wrap').not('.slick-initialized').slick({
          lazyLoad: 'ondemand',
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          speed: 450,
          touch: true,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          dots: false,
          responsive: [
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                }
              }
            ]
      });

      $('.gal__wrap').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
          var i = (currentSlide ? currentSlide : 0) + 1;
          $('.pagingInfo').text(i + '/' + slick.slideCount);
      });
    }
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());